import React from "react";
import "./style.css";
import { Download as DownloadIcon } from "@mui/icons-material";
import reactElementToJSXString from "react-element-to-jsx-string";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import PlaceIcon from "@mui/icons-material/Place";
import {
  CardContent,
  Typography,
  Card,
  Grid,
  Box,
  Button,
  IconButton,
  CircularProgress,
} from "@mui/material";
import ViewMapReplayModal from "./TeamSafeMap/ViewMapReplay";
import { usePDF, Document } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { convertMinutes, getAccessToken } from "../../helpers/helpers";
import {
  leftCardStyle,
  downloadLinkText,
  downloadIconContainerStyle,
  downloadIconStyle,
  scrollableTableStyle,
  containerStyle,
} from "../../styles/styles";
import moment from "moment";
import GoBack from "../../../components/GoBack/GoBack";
import ICCReportPdf from "../../PdfTemplates/InfectionControlConsequencesPdf";
import MapReplayPdf from "../../PdfTemplates/MapReplayPdf";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import ContactRiskSelect from "../../ContactRiskSelect/ContactRiskSelect";
import { TwoLineDate, EncounterDate } from "../../../lib/dayjsConfig";

const consequencesTypeStyle = {
  padding: 0,
  mb: 1.1,
  color: "#333",
  fontWeight: 600,
  fontSize: 14,
  marginRight: "8px",
  marginTop: "6px",
  fontFamily: "Open Sans !important",
};

const pillGroupItemStyle = {
  display: "inline-flex",
  flexDirection: "column",
  marginRight: "30px",
};

const leftButtonGroupStyle = {
  display: "inline-flex",
};

const downloadContainerStyle = {
  display: "inline-flex",
  alignItems: "center",
  position: "relative",
  marginTop: "6px",
};

const caseNameStyle = {
  fontWeight: 600,
  fontSize: 12,
  color: "#333333",
  lineHeight: "2.35",
};

export default function InfectionControlConsequences({
  user,
  pdfHeaderImage,
  currentLocation,
}) {
  const { state } = useLocation();
  const { getAccessTokenSilently } = useAuth0();

  const { infectionId, infectedPerson } = state;
  const [actionsString, setActionsString] = React.useState(
    infectedPerson.actions
      ? JSON.parse(infectedPerson.actions)
          .map((action) => action.value)
          .join(", ")
      : ""
  );
  const [contacts, setContacts] = React.useState();
  const [replayModalOpen, setReplayModalOpen] = React.useState(false);
  const [map, setMap] = React.useState();
  const [nextcontact, setNextcontact] = React.useState();
  const [previouscontact, setPreviouscontact] = React.useState();
  const [currentContact, setCurrentContact] = React.useState();
  const [pathogens, setPathogens] = React.useState();
  const [minDuration, setMinDuration] = React.useState(null);
  const [maxProximity, setMaxProximity] = React.useState(null);
  const [proximityFilter, setProximityFilter] = React.useState(null);
  const [intensityFilter, setIntensityFilter] = React.useState(null);
  const [durationFilter, setDurationFilter] = React.useState(null);
  const [ICCReportInstance, updateICCPDF] = usePDF(<Document></Document>);
  const [accessToken, setAccessToken] = React.useState();
  const [currentEncounter, setCurrentEncounter] = React.useState(0);
  const [showLoadingSpinner, setShowLoadingSpinner] = React.useState(true);
  const [encounterRisk, setEncounterRisk] = React.useState(
    currentContact &&
      currentEncounter &&
      currentContact.encounters &&
      currentContact.encounters[currentEncounter].risk
      ? currentContact.encounters[currentEncounter].risk
      : "Open"
  );
  const [contactRisk, setContactRisk] = React.useState(
    currentContact && currentContact.risk ? currentContact.risk : "Open"
  );

  const [MapReplayPDFInstance, updateMapReplayPDF] = usePDF(
    <Document></Document>
  );
  const apiRef = useGridApiRef();
  const [visibleData, setVisibleData] = React.useState();

  const lastPdfTitle = React.useRef(null);
  const [proximities, setProximities] = React.useState([
    { value: 25, label: "25 cm" },
    { value: 50, label: "50 cm" },
    { value: 75, label: "75 cm" },
    { value: 100, label: "1 meter" },
    { value: 125, label: "1 meter 25 cm" },
    { value: 150, label: "1 meter 50 cm" },
    { value: 175, label: "1 meter 75 cm" },
    { value: 200, label: "2 meters" },
    { value: 250, label: "2 meters 50 cm" },
    { value: 300, label: "3 meters" },
    { value: 350, label: "3 meters 50 cm" },
    { value: 400, label: "4 meters" },
    { value: 450, label: "4 meters 50 cm" },
    { value: 500, label: "5 meters" },
    { value: 1000, label: "10 meters" },
    { value: 2000, label: "20 meters" },
    { value: 5000, label: "50 meters" },
  ]);

  const [durations, setDurations] = React.useState([
    { value: 1, label: "1 min" },
    { value: 2, label: "2 min" },
    { value: 3, label: "3 min" },
    { value: 4, label: "4 min" },
    { value: 5, label: "5 min" },
    { value: 10, label: "10 min" },
    { value: 15, label: "15 min" },
    { value: 20, label: "20 min" },
    { value: 25, label: "25 min" },
    { value: 30, label: "30 min" },
    { value: 35, label: "35 min" },
    { value: 45, label: "45 min" },
    { value: 75, label: "1 hour 15min" },
    { value: 90, label: "1 hour 30min" },
    { value: 105, label: "1 hour 45min" },
    { value: 120, label: "2 hours" },
    { value: 180, label: "3 hours" },
    { value: 240, label: "4 hours" },
    { value: 300, label: "5 hours" },
    { value: 600, label: "10 hours" },
  ]);

  const [intensities, setIntensities] = React.useState([
    { value: 0, label: "0" },
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
    { value: 8, label: "8" },
    { value: 9, label: "9" },
    { value: 10, label: "10" },
  ]);

  const [currentContactPdfTable, setCurrentContactPdfTable] = React.useState();
  const timeoutRef = React.useRef(null);

  const RISK_LEVELS = ["Low", "Medium", "High", "Open"];

  const totalEncountersCount = contacts?.map((contact) => contact.encounters.length).reduce((a, b) => a + b, 0) ?? 0;
  const visibleEncountersCount = visibleData?.map((contact) => contact.encounters.length).reduce((a, b) => a + b, 0) ?? 0;


  const handleReplayModalOpen = () => setReplayModalOpen(true);

  const handleProximityChange = (event) => {
    setProximityFilter(event.target.value);
  };

  const handleIntensityChange = (event) => {
    setIntensityFilter(event.target.value);
  };

  const handleDurationChange = (event) => {
    setDurationFilter(event.target.value);
  };

  const clearFilters = () => {
    setProximityFilter("Show all");
    setDurationFilter("Show all");
    setIntensityFilter("Show all");
    setVisibleData(
      contacts.map((contact) => ({
        ...contact,
        id: contact.id ? contact.id : contact.encounters[0].contactTagSerial,
      }))
    );
  };

  const getTransmissionType = (pathogen) => {
    let transmissionType;
    pathogens.forEach((p) => {
      if (p.pathogen === pathogen) {
        if (p.isAirborne) {
          transmissionType = "Airborne";
        }
        if (p.isDroplet) {
          transmissionType = "Droplet";
        }
        if (p.isDirect) {
          transmissionType = "Direct";
        }
        if (p.isIndirect) {
          transmissionType = "Indirect";
        }
      }
      return "";
    });
    return transmissionType;
  };

  const fetchPathogens = async () => {
    try {
      const pathogenData = await fetch(
        `${process.env.REACT_APP_API_URL}/pathogens/policies`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const pathogenDataJson = await pathogenData.json();

      const pathogenArray = pathogenDataJson.records;
      pathogenArray.map((pathogen) => {
        if (pathogen.pathogen === infectedPerson.pathogen) {
          setMinDuration(pathogen.durationBoundaryMinutes);
          setMaxProximity(pathogen.distanceBoundaryCm);
        }
      });
      setPathogens(pathogenArray);
    } catch (e) {
      console.log(e);
    }
  };

  const handleGoToNextcontact = () => {
    let nextIndex = contacts.findIndex(
      (contact) => contact.id === nextcontact.id
    );

    handleViewReplayOnMap(nextcontact, nextIndex);
  };

  const handleGoToPreviouscontact = () => {
    let previousIndex = contacts.findIndex(
      (contact) => contact.id === previouscontact.id
    );

    handleViewReplayOnMap(previouscontact, previousIndex);
  };

  const handleChangeEncounter = (e) => {
    setCurrentEncounter(e.target.value);
  };

  const handleReplayModalClose = () => {
    setCurrentContact(null);
    setCurrentContactPdfTable(null);
    setPreviouscontact(null);
    setNextcontact(null);
    setReplayModalOpen(false);
  };

  const handleViewReplayOnMap = async (contact, index) => {
    setCurrentContact(contact);
    setCurrentEncounter(0);

    handleReplayModalOpen();
  };

  const renderICCPdf = async () => {
    const reportIdResp = await fetch(
      `${process.env.REACT_APP_RTLS_URL}/report-id`
    );
    const reportIdJson = await reportIdResp.json();
    const reportId = reportIdJson.id;

    const pdf = ICCReportPdf(
      user.firstName + " " + user.lastName,
      user.email,
      pdfHeaderImage,
      infectedPerson,
      contacts,
      actionsString,
      reportId
    );

    await fetch(`${process.env.REACT_APP_RTLS_URL}/reports/${reportId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        reportName: `Proxximos-ICC-${infectedPerson.firstName}-${
          infectedPerson.lastName
        }-${moment(new Date()).format("LLL")}.pdf`,
        reportContent: encodeURI(reactElementToJSXString(pdf)),
        userId: user.userId,
      }),
    });

    updateICCPDF(pdf);
  };

  React.useEffect(() => {
    if (ICCReportInstance && ICCReportInstance.blob) {
      saveAs(
        ICCReportInstance.blob,
        `Proxximos-ICC-${infectedPerson.firstName}-${
          infectedPerson.lastName
        }-${moment(new Date()).format("LLL")}.pdf`
      );
    }
  }, [ICCReportInstance]);

  const renderMapReplayPdf = async () => {
    const reportIdResp = await fetch(
      `${process.env.REACT_APP_RTLS_URL}/report-id`
    );
    const reportIdJson = await reportIdResp.json();
    const reportId = reportIdJson.id;

    const pdf = MapReplayPdf(
      user.firstName + " " + user.lastName,
      user.email,
      pdfHeaderImage,
      infectedPerson,
      contacts,
      currentContact,
      actionsString,
      reportId
    );

    updateMapReplayPDF(pdf);

    await fetch(`${process.env.REACT_APP_RTLS_URL}/reports/${reportId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        reportName: `Proxximos-ICC-contact-${
          currentContact.infectedPersonSerial
        }-${currentContact.contactPersonSerial}-${moment(new Date()).format(
          "LLL"
        )}.pdf`,
        reportContent: encodeURI(reactElementToJSXString(pdf)),
        userId: user.userId,
      }),
    });
  };

  React.useEffect(() => {
    if (MapReplayPDFInstance && MapReplayPDFInstance.blob && currentContact) {
      const pdfTitle = `Proxximos-ICC-contact-${
        currentContact.infectedPersonSerial
      }-${currentContact.contactPersonSerial}-${moment(new Date()).format(
        "LLL"
      )}.pdf`;

      if (lastPdfTitle.current === null) {
        lastPdfTitle.current = setTimeout(() => {
          lastPdfTitle.current = null;
        }, 1000);
        saveAs(MapReplayPDFInstance.blob, pdfTitle);
      }
    }
  }, [MapReplayPDFInstance]);

  const filterContacts = () => {
    const newContacts = contacts.map((contact) => {
      // contact.encounters is referring to actual contacts
      const newContactsArray = contact.encounters.filter((contact) => {
        let include = true;

        if (proximityFilter && proximityFilter !== "Show all") {
          include = include && contact.averageDistanceCm <= proximityFilter;
        }

        if (durationFilter && durationFilter !== "Show all") {
          include = include && contact.durationSeconds / 60 >= durationFilter;
        }

        if (intensityFilter && intensityFilter !== "Show all") {
          include =
            include && parseInt(contact.intensity) >= parseInt(intensityFilter);
        }

        return include;
      });

      return {
        ...contact,
        encounters: newContactsArray,
      };
    });

    const filteredContacts = newContacts.filter((contact) => {
      return contact.encounters.length > 0;
    });

    const visibleDataObject = filteredContacts.map((contact) => ({
      ...contact,
      id: contact.id ? contact.id : contact.encounters[0].contactTagSerial,
    }));

    setVisibleData(
      filteredContacts.map((contact) => ({
        ...contact,
        id: contact.id ? contact.id : contact.encounters[0].contactTagSerial,
      }))
    );
  };

  const fetchEncounters = async () => {
    try {
      let contactsData, contactsDataJson, contactsArray;

      contactsData = await fetch(
        `${process.env.REACT_APP_API_URL}/cases/${infectionId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      contactsDataJson = await contactsData.json();

      contactsArray = contactsDataJson.contactPeople;
      const formattedContacts = contactsArray.map((contact) => ({
        ...contact,
        id: contact.id ? contact.id : contact.encounters[0].contactTagSerial,
      }));

      setContacts(contactsArray);
      setShowLoadingSpinner(false);
      setVisibleData(formattedContacts);

      if (currentContact) {
        // update currentContact if it's been changed
        const currentContactIndex = contactsArray.findIndex(
          (contact) => contact.id === currentContact.id
        );

        setCurrentContact(contactsArray[currentContactIndex]);
      }
    } catch (e) {
      console.log(e);
      if (timeoutRef.current === null) {
        clearTimeout(timeoutRef.current);
        setTimeout(() => {
          timeoutRef.current = fetchEncounters();
        }, 1000);
      }
    }
  };

  const SUMMARY_COLS = [
    {
      field: "id",
    },
    {
      field: "contactPerson",
      headerName: "Name",
      minWidth: 145,
      flex: 1,
      align: "left",
      headerAlign: "left",
      editable: false,
      renderCell: (params) => {
        if (!params.value || params.value.fullName.toLowerCase().indexOf("unknown") > -1) {
          const firstContact = params.row.encounters.length
            ? params.row.encounters[0]
            : null;
          if (!firstContact) {
            return <div>Unknown Person</div>;
          }
          return <div>Unknown Person ({firstContact.contactTagSerial})</div>;
        }
        return <div>{params.value.fullName}</div>;
      },
    },
    {
      field: "Role",
      headerName: "Role",
      align: "left",
      headerAlign: "left",
      editable: false,
      type: "string",
      valueGetter: (params) => {
        return params.row.contactPerson ? params.row.contactPerson.role : "";
      },
    },
    {
      field: "Encounters",
      headerName: "Encounters",
      align: "left",
      headerAlign: "left",
      minWidth: 100,
      type: "number",
      editable: false,
      valueGetter: (params) => {
        return params.row.encounters.length;
      },
    },
    {
      field: "Start",
      headerName: "Start time",
      type: "string",
      align: "left",
      headerAlign: "left",
      minWidth: 145,
      editable: false,
      renderCell: (params) => {
        const firstContact = params.row.encounters.length
          ? params.row.encounters[0]
          : 0;
        if (!firstContact) {
          return "";
        }
        return (
          <EncounterDate date={firstContact.start} />
        );
      },
    },
    {
      field: "DurationSeconds",
      headerName: "Duration",
      minWidth: 130,
      editable: false,
      type: "string",
      align: "left",
      headerAlign: "left",
      valueGetter: (params) => {
        let sum = 0;

        if (params.row.encounters.length === 0) {
          return `0 minutes`;
        }

        params.row.encounters.map((contact, key) => {
          sum += parseInt(contact.durationSeconds);
          return "";
        });
        return `${convertMinutes(parseInt(sum) / 60)}`;
      },
    },
    {
      field: "AverageDistanceCm",
      headerName: "Proximity",
      minWidth: 130,
      editable: false,
      align: "left",
      type: "string",
      headerAlign: "left",
      valueGetter: (params) => {
        let smallestProximity = Infinity;

        params.row.encounters.map((contact, key) => {
          if (parseInt(contact.averageDistanceCm) < smallestProximity) {
            smallestProximity = parseInt(contact.averageDistanceCm);
          }
          return "";
        });

        return `${smallestProximity} cm`;
      },
    },
    {
      field: "Intensity",
      headerName: "Intensity",
      valueGetter: (params) => {
        return params.row.intensity ? Math.round(params.row.intensity) : 0;
      },
      editable: false,
      type: "number",
      align: "left",
      width: 125,
      headerAlign: "left",
      sortComparator: (v1, v2) => {
        return parseInt(v1) - parseInt(v2);
      },
    },
    {
      field: "risk",
      headerName: "Risk",
      type: "string",
      editable: false,
      align: "left",
      width: 105,
      headerAlign: "left",

      renderCell: (params) => {
        return (
          <>
            <ContactRiskSelect
              value={params.value ? params.value : "Open"}
              contact={params.row}
              handleRefresh={fetchEncounters}
            />
          </>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "",
      editable: false,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => {
        return (
          <IconButton onClick={() => handleViewReplayOnMap(params.row)}>
            <PlaceIcon />
          </IconButton>
        );
      },
    },
  ];

  const handleRowClick = (event) => {
    // TODO
  };

  React.useEffect(() => {
    if (!accessToken) {
      return;
    }

    if (!contacts) {
      fetchEncounters();
    }

    if (!pathogens) {
      fetchPathogens();
    }
  }, [accessToken]);

  React.useEffect(() => {
    const getAccessToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
            scope: "openid profile email",
          },
        });

        setAccessToken(accessToken);
      } catch (e) {
        console.log(e.message);
      }
    };

    getAccessToken();
  }, []);

  return (
    <div>
      {currentContact ? (
        <ViewMapReplayModal
          handleOpen={handleReplayModalOpen}
          handleClose={handleReplayModalClose}
          handleGoToNextcontact={handleGoToNextcontact}
          handleGoToPreviouscontact={handleGoToPreviouscontact}
          handleChangeEncounter={handleChangeEncounter}
          handleRefresh={fetchEncounters}
          encounterRisk={encounterRisk}
          contactRisk={contactRisk}
          currentLocation={currentLocation}
          displayDownloadReportButton={
            pdfHeaderImage &&
            infectedPerson &&
            contacts &&
            currentContact &&
            actionsString
          }
          // PdfTemplate={MemoizedMapReplayPdf}
          renderPdf={renderMapReplayPdf}
          previouscontact={previouscontact}
          nextcontact={nextcontact}
          open={replayModalOpen}
          user={user}
          replay={true}
          infectedPerson={{
            ...infectedPerson,
            transmissionType: getTransmissionType(infectedPerson.pathogen),
            infectedUserId: infectedPerson.personId,
          }}
          currentContact={currentContact}
          tags={[
            currentContact.serialAlphanumeric1,
            currentContact.serialAlphanumeric2,
          ]}
          minDate={currentContact.onsetTime}
          maxDate={currentContact.endTime}
          minDuration={minDuration}
          maxProximity={maxProximity}
          currentEncounter={currentEncounter}
          setCurrentEncounter={setCurrentEncounter}
          riskLevels={RISK_LEVELS}
        />
      ) : (
        <></>
      )}

      <Box style={containerStyle}>
        <GoBack />

        <Typography variant="h5" component="div" sx={{ fontWeight: "bold" }}>
          Infection Control Consequences
        </Typography>

        <br></br>
        <Grid container justifyContent="center" spacing={5}>
          <Grid item xs={2}>
            <h6
              className="table-title case-title"
              style={{ marginTop: 27, marginBottom: 12 }}
            >
              Case
            </h6>
            <Card style={leftCardStyle}>
              <CardContent>
                <div className="left-card-title">Name of case</div>
                <Typography variant="body2" sx={caseNameStyle}>
                  {infectedPerson.infectedPerson
                    ? infectedPerson.infectedPerson.fullName
                    : `Unassigned tag`}
                </Typography>

                <div class="horizontal-line"></div>

                <div className="left-card-title">Role</div>
                <Typography variant="body2">
                  {infectedPerson.infectedPerson
                    ? infectedPerson.infectedPerson.role
                    : ""}
                </Typography>

                <div class="horizontal-line"></div>

                <div className="left-card-title">Status</div>
                <Typography variant="body2">
                  {infectedPerson ? infectedPerson.status : ""}
                </Typography>

                <div class="horizontal-line"></div>

                <div className="left-card-title">Time and date of onset</div>
                <Typography variant="body2">
                  <TwoLineDate date={infectedPerson.symptomsStart} />
                </Typography>

                <div class="horizontal-line"></div>

                <div className="left-card-title">Pathogen or disease</div>
                <div variant="body2" className="left-card-content">
                  {infectedPerson.pathogen ? infectedPerson.pathogen : "Open"}
                </div>

                <div class="horizontal-line"></div>
                <div className="left-card-title">Infection site</div>
                <div variant="body2" className="left-card-content">
                  {infectedPerson.infectionSite}
                </div>

                <div class="horizontal-line"></div>

                <div className="left-card-title"># contacts</div>
                <Typography variant="body2">
                  {infectedPerson.contactPeople
                    ? infectedPerson.contactPeople.length
                    : 0}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={10}>
            <>
              {!showLoadingSpinner ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "20px",
                    }}
                  >
                    <div style={leftButtonGroupStyle}>
                      {minDuration !== null && maxProximity !== null ? (
                        <div className="pill">
                          <div className="drop-menu-label" for="duration">
                            Duration of contact
                          </div>
                          <select
                            className="drop-menu"
                            name="duration"
                            id="duration"
                            value={
                              durationFilter ? durationFilter : minDuration
                            }
                            onChange={handleDurationChange}
                          >
                            <option value={"Show all"}>Show all</option>

                            {durations.map((duration) => {
                              return duration.value >= minDuration ? (
                                <option
                                  value={duration.value}
                                >{`> ${duration.label}`}</option>
                              ) : (
                                <></>
                              );
                            })}
                          </select>

                          <div className="drop-menu-label" for="proximity">
                            Proximity
                          </div>
                          <select
                            className="drop-menu"
                            name="proximity"
                            id="proximity"
                            onChange={handleProximityChange}
                            value={
                              proximityFilter ? proximityFilter : maxProximity
                            }
                          >
                            <option value={"Show all"}>Show all</option>

                            {proximities.map((proximity) => {
                              return proximity.value <= maxProximity ? (
                                <option value={proximity.value}>
                                  {`< ${proximity.label}`}
                                </option>
                              ) : (
                                <></>
                              );
                            })}
                          </select>

                          <div className="drop-menu-label" for="duration">
                            Intensity
                          </div>
                          <select
                            className="drop-menu"
                            name="intensity"
                            id="intensity"
                            value={intensityFilter}
                            onChange={handleIntensityChange}
                          >
                            <option value={"Show all"}>Show all</option>

                            {intensities.map((intensity) => (
                              <option value={intensity.value}>
                                {`> ${intensity.label}`}
                              </option>
                            ))}
                          </select>
                          <button
                            className="search-button"
                            onClick={filterContacts}
                          >
                            {" "}
                            Filter{" "}
                          </button>
                          <button
                            style={{
                              background: "none",
                              border: "none",
                              margin: 0,
                              fontSize: 8,
                            }}
                            onClick={clearFilters}
                          >
                            <Typography
                              id="type-menu-label"
                              sx={{
                                ...consequencesTypeStyle,
                                ...downloadLinkText,
                                minWidth: 90,
                                cursor: "pointer",
                                color: "blue",
                                margin: 0,
                              }}
                            >
                              Clear filters
                            </Typography>
                          </button>
                        </div>
                      ) : (
                        <> </>
                      )}
                    </div>
                    <div style={{ display: "inline-block" }}>
                      <div
                        style={{
                          ...downloadContainerStyle,
                          marginTop: "-12px",
                        }}
                      >
                        <Button onClick={renderICCPdf}>
                          <div
                            style={{
                              ...downloadContainerStyle,
                            }}
                          >
                            <Typography sx={downloadLinkText}>
                              Download report
                            </Typography>
                            <div
                              className="icon-container"
                              style={downloadIconContainerStyle}
                            >
                              <DownloadIcon
                                className="download-icon"
                                style={downloadIconStyle}
                              />
                            </div>
                          </div>
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style={leftButtonGroupStyle}>
                      <Typography
                        id="type-menu-label"
                        sx={{
                          consequencesTypeStyle,
                          fontSize: 12,
                          fontWeight: "bold",
                          marginLeft: "30px",
                        }}
                      >
                        Encounters shown: {visibleEncountersCount} of {totalEncountersCount} found
                      </Typography>
                      <br />
                      <br />
                    </div>
                  </div>
                  <Card sx={scrollableTableStyle}>
                    <DataGrid
                      apiRef={apiRef}
                      sx={{ backgroundColor: "#fff" }}
                      autoHeight
                      columnVisibilityModel={{
                        id: false,
                      }}
                      onRowClick={handleRowClick}
                      onRowDoubleClick={handleRowClick}
                      // rowHeight={"auto"}
                      getRowHeight={() => "auto"}
                      experimentalFeatures={{
                        ariaV7: true,
                        newEditingApi: true,
                      }}
                      columns={SUMMARY_COLS}
                      rows={visibleData}
                      // editMode="row"
                      // rowModesModel={rowModesModel}
                      // onRowModesModelChange={handleRowModesModelChange}
                      // onRowEditStop={handleRowEditStop}
                      // processRowUpdate={(props) => {
                      //   return processRowUpdate({ ...props });
                      // }}
                    />
                  </Card>
                </>
              ) : (
                <>
                  <Grid container>
                    <Grid item xs={12}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "100px",
                        }}
                      >
                        <CircularProgress />
                      </div>
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
