import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';

dayjs.locale('en-gb');

var advancedFormat = require("dayjs/plugin/advancedFormat");
dayjs.extend(advancedFormat);

export default dayjs;


export function TwoLineDate({ date }) {

    return (
        <>
            <div style={{ wordWrap: "break-word" }}>
                {dayjs(date).format('LT, dddd')}
                <br />
                {dayjs(date).format('ll')}
            </div>
        </>
    );

}


export function EncounterDate({ date }) {

    return (
        <>
            <div style={{ wordWrap: "break-word" }}>
                {dayjs(date).format('LT ddd D MMM')}
            </div>
        </>
    );

}

