// This file contains the context and provider for the application. 
// It is used to store common values, such as the user's organization ID. 
// This context is used to pass the organization ID to the components that need it.

import React, { createContext, useContext, useState } from 'react';

// Create the context
const AppContext = createContext();

// Create the provider
export const AppProvider = ({ children }) => {
  const [orgId, setOrgId] = useState(null);

  return (
    <AppContext.Provider value={{ orgId, setOrgId }}>
      {children}
    </AppContext.Provider>
  );
};

// Create a custom hook for accessing the context
export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};
