import React from "react";
import { renderToString } from "react-dom/server";
import FloorplanSvg from "../../../FloorplanSvg/FloorplanSvg";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import DeadBatteryIcon from "../../../DeadBatteryIcon/DeadBatteryIcon.tsx";
import styles from "../../../../App.css";
import PanZoom from "react-easy-panzoom";
import PersonIcon from "../../../people/PersonIcon.jsx";
import {
  MapContainer,
  Marker,
  Popup,
  ImageOverlay,
  Polygon,
} from "react-leaflet";
import ZoomInIcon from "../../../ZoomInIcon/ZoomInIcon";
import ZoomOutIcon from "../../../ZoomOutIcon/ZoomOutIcon";
import * as L from "leaflet";
import { index } from "d3";

const zoomIconStyle_def = {
  color: "#283555",
  fontSize: 31,
  background: "transparent",
  margin: 0,
  padding: 0,
};

const zoomIconContainerStyle_def = {
  position: "relative",
  width: 50,
  height: 50,
  padding: 10,
  backgroundColor: "#eaf0ff",
  marginTop: 25,
  marginRight: 27,
  display: "block",
  border: "none ",
  cursor: "pointer",
};

const zoomPositionAbsoluteValues = {
  tl: {
    left: 30,
    top: 15,
  },
  br: {
    right: "-17px",
    bottom: 59,
  },
  tr: {
    right: "-17px",
    top: 59,
  },
};

const zoomPositionStyle_def = {
  position: "absolute",
  display: "block",
  zIndex: 5,
};

const mapIconListStyle_def = {
  position: "absolute",
  display: "inline-flex",
  flexDirection: "column",
  zIndex: 5,
  top: 25,
  left: 40,
};

function MapTab({
  zoomIconContainerStyle = zoomIconContainerStyle_def,
  zoomPositionStyle = zoomPositionStyle_def,
  zoomIconStyle = zoomIconStyle_def,
  mapIconListStyle = mapIconListStyle_def,
  iconBackgroundColor = "#4DB735",
  iconStyle,
  type,
  bgColor = "white",
  controlsPosition = "tl",
  data,
  mapIconMetadata,
  user,
  currentContact,
  seekTime,
  lastFrameSteps,
  focusedPerson,
  width,
  height,
  contactReplayData,
  currentEncounter,
  caseReplayData,
  currentMap,
  map,
}) {
  const [openTooltip, setOpenTooltip] = React.useState();

  const focusedPersonX = React.useRef();
  const focusedPersonY = React.useRef();
  const panZoom = React.useRef();
  const mapContainerRef = React.useRef();
  const leafletMapRef = React.useRef();

  const MAP_CONTAINER_HEIGHT = "660px";
  const SCALE_FACTOR_X = currentMap.pixelScaleX;
  const SCALE_FACTOR_Y = -currentMap.pixelScaleY;
  const X_ORIGIN = currentMap.origin.coordinates[0];
  const Y_ORIGIN = currentMap.origin.coordinates[1];

  const mapContainerStyle = {
    backgroundColor: bgColor,
    padding: 0,
    borderRadius: "8px",
    overflow: "hidden",
  };

  zoomPositionStyle = {
    ...zoomPositionStyle,
    ...zoomPositionAbsoluteValues[controlsPosition],
  };

  const handleOpenTooltip = (id) => {
    if (openTooltip !== id) {
      setOpenTooltip(id);
    }
  };

  const HtmlTooltip = styled(({ className, id, ...props }) => {
    return (
      <Tooltip
        {...props}
        arrow
        open={openTooltip === id}
        classes={{ popper: className }}
        TransitionProps={{ timeout: 0 }}
      />
    );
  })(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.white,
      "&::before": {
        backgroundColor: "#283555",
        border: "none",
      },
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#283555",
      color: "#fff",
      borderRadius: "6px",
      fontFamily: "Open Sans",
      minWidth: "160px",
      fontSize: 12,
      fontWeight: 700,
      padding: "16px 20px",
      maxWidth: 220,
      border: "none",
    },
  }));

  const indexCaseIcon = L.divIcon({
    className: "index-case-icon",
    forceZIndex: 999,
    html: renderToString(
      <PersonIcon
        style={{
          color: "#fff",
          width: "110px",
          height: "110px",
          backgroundColor: "#E326DC",
        }}
      />
    ),
  });

  const indexCaseTrailIcon = L.divIcon({
    className: "index-case-trail-icon",
    forceZIndex: 1,
    html: renderToString(
      <div
        style={{
          width: "45px",
          height: "45px",
          display: "flex",
          // justifyContent: "center",
          // alignItems: "center",
          position: "absolute",
          zIndex: 1,
        }}
      >
        <div
          style={{
            borderRadius: "30px",
            backgroundColor: "#e572ef",
            width: 20,
            height: 20,
          }}
        ></div>
      </div>
    ),
  });

  const contactPersonIcon = L.divIcon({
    className: "contact-icon",
    forceZIndex: 999,
    html: renderToString(
      <PersonIcon
        style={{
          color: "#fff",
          width: "40px",
          height: "45px",
          backgroundColor: "#FF892D",
        }}
      />
    ),
  });

  const contactPersonTrailIcon = L.divIcon({
    className: "contact-trail-icon",
    forceZIndex: 1,
    html: renderToString(
      <div
        style={{
          width: "40px",
          height: "45px",
          display: "flex",
          // justifyContent: "center",
          // alignItems: "center",
          position: "absolute",
          zIndex: 1,
        }}
      >
        <div
          style={{
            borderRadius: "30px",
            backgroundColor: "#ffb65e",
            width: 20,
            height: 20,
          }}
        ></div>
      </div>
    ),
  });

  const preContactPersonIcon = L.divIcon({
    className: "pre-contact-icon",
    forceZIndex: 999,
    html: renderToString(
      <PersonIcon
        style={{
          color: "#fff",
          backgroundColor: "#FF892D",
        }}
      />
    ),
  });

  const preContactPersonTrailIcon = L.divIcon({
    className: "pre-contact-trail-icon",
    forceZIndex: 1,
    html: renderToString(
      <div
        style={{
          width: "40px",
          height: "45px",
          display: "flex",
          // justifyContent: "center",
          // alignItems: "center",
          position: "absolute",
          zIndex: 1,
        }}
      >
        <div
          style={{
            borderRadius: "30px",
            backgroundColor: "#ffb65e",
            width: 20,
            height: 20,
          }}
        ></div>
      </div>
    ),
  });

  const mapIconStatic = (index, beaconId) => {
    return (
      <>
        {(mapIconMetadata[beaconId]["type"] &&
          mapIconMetadata[beaconId]["type"] === "asset") ||
        type === "asset" ? (
          <div
            style={{
              background: mapIconMetadata[beaconId].color,
              borderRadius: "100%",
              width: "21px",
              height: "21px",
            }}
          ></div>
        ) : (
          <PersonIcon
            id={`map-icon-${index}`}
            key={`map-icon-${index}`}
            posX={xPos(data[index].x)}
            posY={yPos(data[index].y)}
            style={{
              color: "#fff",
              backgroundColor: mapIconMetadata[beaconId].color,
              width: "34px",
              height: "34px",
              marginTop: "-5px",
              marginLeft: "1px",
            }}
          />
        )}
      </>
    );
  };

  const yPos = (pos) => {
    return Y_ORIGIN + pos * SCALE_FACTOR_Y;
  };

  const xPos = (pos) => {
    return X_ORIGIN + pos * SCALE_FACTOR_X;
  };

  const replayMapIcon = (positionsArray, serial, index) => {
    const currentContactOnsetTime = new Date(
      `${currentContact.encounters[currentEncounter].start}`
    ).getTime();

    const indexCasePersonSerialMatch =
      serial === currentContact.encounters[currentEncounter].sourceTagSerial;

    const contactPersonSerialMatch =
      serial === currentContact.encounters[currentEncounter].contactTagSerial;

    const infectionOccurred =
      new Date(`${positionsArray[index].timestamp}`).getTime() >=
        currentContactOnsetTime && contactPersonSerialMatch;

    if (indexCasePersonSerialMatch) {
      console.log("index case person");
      if (lastFrameSteps.current === index) {
        // index case person icon
        return (
          <Marker
            icon={indexCaseIcon}
            position={[
              yPos(positionsArray[index].y),
              xPos(positionsArray[index].x),
            ]}
          >
            <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup>
          </Marker>
        );
      } else {
        return (
          <Marker
            icon={indexCaseTrailIcon}
            position={[
              yPos(positionsArray[index].y),
              xPos(positionsArray[index].x),
            ]}
          >
            <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup>
          </Marker>
        );
      }
    }

    if (infectionOccurred && contactPersonSerialMatch) {
      // contact person icon

      // this code block ensures that the map is always centered on the focused person
      if (focusedPerson) {
        const currentTransform =
          panZoom.current.container.current.firstChild.style.transform;

        focusedPersonX.current = xPos(positionsArray[focusedPerson].x);
        focusedPersonY.current = yPos(positionsArray[focusedPerson].y);

        // width = map container width
        // height = map container height
        const updatedTransform = currentTransform.replace(
          /matrix\(([^,]+),([^,]+),([^,]+),([^,]+),[^,]+,[^,]+\)/,
          `matrix($1,$2,$3,$4,${-focusedPersonX.current + width / 2},${
            -focusedPersonY.current + height / 2
          })`
        );

        // Apply the updated transform back to the element
        panZoom.current.container.current.firstChild.style.transform =
          updatedTransform;
      }

      if (lastFrameSteps.current === index) {
        return (
          <Marker
            icon={contactPersonIcon}
            position={[
              yPos(positionsArray[index].y),
              xPos(positionsArray[index].x),
            ]}
          >
            <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup>
          </Marker>
        );
      } else {
        return (
          <Marker
            icon={contactPersonTrailIcon}
            position={[
              yPos(positionsArray[index].y),
              xPos(positionsArray[index].x),
            ]}
          >
            <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup>
          </Marker>
        );
      }
    }

    if (!infectionOccurred && contactPersonSerialMatch) {
      // un-infected contact person icon

      if (lastFrameSteps.current === index) {
        return (
          <Marker
            icon={preContactPersonIcon}
            position={[
              yPos(positionsArray[index].y),
              xPos(positionsArray[index].x),
            ]}
          >
            <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup>
          </Marker>
        );
      } else {
        return (
          <Marker
            icon={preContactPersonTrailIcon}
            position={[
              yPos(positionsArray[index].y),
              xPos(positionsArray[index].x),
            ]}
          >
            <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup>
          </Marker>
        );
      }
    }
  };

  const mapIcon = (index, beaconId) => {
    const currentDate = new Date(
      new Date().toLocaleString("en", { timeZone: "Europe/London" })
    );
    const mapIconLastUpdatedDate = new Date(
      data[index].lastLocationTime
    ).getTime();
    if (currentDate - mapIconLastUpdatedDate > 30000) {
      return (
        <div
          style={{
            background:
              mapIconMetadata && mapIconMetadata[beaconId]
                ? mapIconMetadata[beaconId].color
                : "transparent",
            borderRadius: "100%",
            width: "90px",
            height: "90px",
            paddingLeft: "13px",
          }}
        >
          <DeadBatteryIcon
            setOpenTooltip={(id) => handleOpenTooltip(id)}
            id={`map-icon-${index}`}
            key={`map-icon-${index}`}
            posX={xPos(data[index].x)}
            posY={yPos(data[index].y)}
            fill={"#000000"}
            style={{
              color:
                mapIconMetadata && mapIconMetadata[beaconId] ? "#fff" : "#000",
              width: "60px",
              height: "64px",
            }}
          />
        </div>
      );
    }
    return (
      <>
        {(mapIconMetadata &&
          mapIconMetadata[beaconId]["type"] &&
          mapIconMetadata[beaconId]["type"] === "asset") ||
        type === "asset" ? (
          <div
            style={{
              background:
                mapIconMetadata && mapIconMetadata[beaconId]
                  ? mapIconMetadata[beaconId].color
                  : iconBackgroundColor,
              borderRadius: "100%",
              width: "31px",
              height: "31px",
              ...(iconStyle ? iconStyle : {}),
            }}
          ></div>
        ) : (
          <PersonIcon
            setOpenTooltip={(id) => handleOpenTooltip(id)}
            id={`map-icon-${index}`}
            key={`map-icon-${index}`}
            posX={xPos(data[index].x)}
            posY={yPos(data[index].y)}
            style={{
              color: "#fff",
              backgroundColor:
                mapIconMetadata && mapIconMetadata[beaconId]
                  ? mapIconMetadata[beaconId].color
                  : iconBackgroundColor,
              width: "44px",
              height: "44px",
              marginTop: "-6px",
              marginLeft: "2px",
            }}
          />
        )}
      </>
    );
  };

  function onZoomIn() {
    if (leafletMapRef.current) {
      leafletMapRef.current.zoomIn();
    }
  }

  function onZoomOut() {
    if (leafletMapRef.current) {
      leafletMapRef.current.zoomOut();
    }
  }

  const bounds = new L.LatLngBounds(
    [
      currentMap?.position1?.coordinates[1] * SCALE_FACTOR_Y,
      currentMap?.position1?.coordinates[0] * SCALE_FACTOR_X,
    ],
    [
      currentMap?.position2?.coordinates[1] * SCALE_FACTOR_Y,
      currentMap?.position2?.coordinates[0] * SCALE_FACTOR_X,
    ]
  );

  return (
    <div style={{ display: "flex" }}>
      <div className="map-container" style={mapContainerStyle}>
        <div className="map-icon-list" style={mapIconListStyle}>
          {data &&
            mapIconMetadata &&
            Object.keys(data).map((index) => {
              const beaconId = data[index].serial_alphanumeric;
              return (
                <>
                  {data[index] ? (
                    <div
                      style={{
                        display: "inline-flex",
                        marginBottom: 15,
                      }}
                    >
                      <div>{mapIconStatic(index, beaconId)}</div>
                      <div
                        style={{
                          marginLeft: "14px",
                          color: "#333333",
                          fontSize: 12,
                          fontWeight: 600,
                          alignSelf: "center",
                        }}
                      >
                        {mapIconMetadata[beaconId].name}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              );
            })}
        </div>
        <div style={zoomPositionStyle}>
          <button
            className="icon-container"
            style={zoomIconContainerStyle}
            onClick={() => {
              onZoomIn();
            }}
          >
            <ZoomInIcon className="download-icon" style={zoomIconStyle} />
          </button>
          <button
            className="icon-container"
            style={zoomIconContainerStyle}
            onClick={onZoomOut}
          >
            <ZoomOutIcon className="download-icon" style={zoomIconStyle} />
          </button>
        </div>

        <div
          className="map-container-inner"
          style={{
            height: MAP_CONTAINER_HEIGHT,
            width: "100%",
          }}
          ref={mapContainerRef}
        >
          <MapContainer
            ref={leafletMapRef}
            center={[
              yPos(contactReplayData[0].y) -
                currentMap?.position2?.coordinates[0] * SCALE_FACTOR_X,
              xPos(contactReplayData[0].x),
            ]}
            crs={L.CRS.Simple}
            zoom={0}
            zoomControl={false}
            scrollWheelZoom={true}
            style={{ height: "1206px", width: "100%" }}
          >
            {map ? (
              <>
                <ImageOverlay
                  url={map}
                  bounds={bounds}
                  opacity={0.5}
                  zIndex={10}
                />
              </>
            ) : (
              <></>
            )}

            {contactReplayData &&
              SCALE_FACTOR_X &&
              SCALE_FACTOR_Y &&
              contactReplayData.map((_row, index) => {
                if (lastFrameSteps.current === undefined) {
                  lastFrameSteps.current = index;
                }

                return (
                  <>
                    {index <= seekTime ? (
                      <>
                        {replayMapIcon(
                          contactReplayData,
                          contactReplayData[0].tagSerial,
                          index
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}

            {caseReplayData &&
              SCALE_FACTOR_X &&
              SCALE_FACTOR_Y &&
              caseReplayData.map((row, index) => {
                if (lastFrameSteps.current === undefined) {
                  lastFrameSteps.current = index;
                }

                return (
                  <>
                    {index <= seekTime ? (
                      <>
                        {replayMapIcon(
                          caseReplayData,
                          caseReplayData[0].tagSerial,
                          index
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}
          </MapContainer>
        </div>
      </div>
    </div>
  );
}

export default MapTab;
